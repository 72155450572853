/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Button, DashboardTopBar, DashboardTopPortal } from "shared";
import {
  AddCompanyClientModal,
  AddCompanyModal,
  DeleteCompanyModal,
  SellSharesModal,
} from "features/companies";
import { CompaniesTable, CompanyInfoModal } from "features/companies";
import { useSearchParams } from "react-router-dom";
import "./CompaniesPage.scss";
import {
  CompanyMyType,
  CompanyStatusType,
  CompanyType,
} from "types/CompanyTypes";
import { useGetMyCompanies, useUpdateCompany } from "hooks/companies";
import { AddSharesModal } from "features/shares";
import { useGetMe } from "hooks/auth";
import { ROLES_ENUM, SVG_ENUM } from "enums";
import TransactionsSideModal from "features/companies/TransactionsSideModal/TransactionsSideModal";
import { isDesktop } from "utils";

export interface CompaniesTabsType {
  tab: "reliance" | "other" | "my" | "archive";
}

const CompaniesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const linkPage = searchParams.get("page");
  interface ModalManage {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }

  const [modalManage, setModalManage] = useState<ModalManage>({
    type: "",
    company: null,
  });
  const [page, setPage] = useState(Number(linkPage) || 1);
  const { data: authorizedUser } = useGetMe();
  const [tab, setTab] = useState<CompaniesTabsType["tab"]>(
    authorizedUser?.role === ROLES_ENUM.USER ? "my" : "reliance"
  );
  const { mutateAsync: updateCompany } = useUpdateCompany();
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;

  const { data: dataOther } = useGetMyCompanies({
    origin: "other",
    enabled: authorizedUser?.role === ROLES_ENUM.USER,
    archived: true,
  });
  const { data: dataReliance } = useGetMyCompanies({
    origin: "reliance",
    enabled: authorizedUser?.role === ROLES_ENUM.USER,
    archived: true,
  });

  const isAnyCompanyInArchive = dataReliance?.length || dataOther?.length;

  const isAddCompanyOpenModal =
    modalManage.type === "addCompany" &&
    authorizedUser?.role === ROLES_ENUM.ADMIN;
  const isAddCompanyClientOpenModal =
    modalManage.type === "addCompany" &&
    authorizedUser?.role === ROLES_ENUM.USER;

  const clearModalManage = () => {
    setModalManage({ type: "", company: modalManage.company });
  };

  const setDetailsUser = (
    company: CompanyType,
    e: React.MouseEvent<any, MouseEvent>
  ) => {
    if (
      e.target instanceof Element &&
      e.target.closest(".context-menu__wrapper")
    ) {
      return;
    }
    setModalManage({ type: "details", company });
  };

  const handleStatusChange = async (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => {
    await updateCompany({ ...company, status });
  };

  const setAddCompany = () =>
    setModalManage({ type: "addCompany", company: null });

  const setEditCompany = (company: CompanyType) =>
    setModalManage({ type: "addCompany", company });

  const setAddClient = (company: CompanyType) =>
    setModalManage({ type: "addClient", company: company });

  const setShowSellShares = (company: CompanyType) =>
    setModalManage({ type: "sellShares", company });

  const setShowDelete = (company: CompanyType) => {
    setModalManage({ type: "delete", company });
  };

  const handleChangeTab = (item: CompaniesTabsType["tab"]) => {
    setTab(item);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("tab", item);
    setSearchParams(newSearchParams);
  };

  const isCompanyInfoOpenModal =
    modalManage.type === "details" && authorizedUser?.role === ROLES_ENUM.ADMIN;

  const isTransactionsSideModalOpen =
    modalManage.type === "details" && authorizedUser?.role === ROLES_ENUM.USER;

  const isTransactionsSellModalOpen = modalManage.type === "sellShares";

  return (
    <>
      <AddCompanyModal
        isOpen={isAddCompanyOpenModal}
        onClose={() => clearModalManage()}
        company={modalManage.company}
      />
      <AddCompanyClientModal
        isOpen={isAddCompanyClientOpenModal}
        onClose={() => clearModalManage()}
        company={modalManage.company as CompanyMyType}
      />

      <SellSharesModal
        isOpen={isTransactionsSellModalOpen}
        onClose={() => clearModalManage()}
        company={modalManage.company as CompanyMyType}
      />

      <CompanyInfoModal
        isOpen={isCompanyInfoOpenModal}
        onClose={() => clearModalManage()}
        company={modalManage.company}
        setAddClient={setAddClient}
      />
      <TransactionsSideModal
        isOpen={isTransactionsSideModalOpen}
        onClose={() => clearModalManage()}
        company={modalManage.company as CompanyMyType}
      />

      <AddSharesModal
        isOpen={modalManage.type === "addClient"}
        onClose={() => clearModalManage()}
        company={modalManage.company}
      />

      <DeleteCompanyModal
        isOpen={modalManage.type === "delete"}
        company={modalManage.company as CompanyMyType}
        onClose={() => clearModalManage()}
      />

      <div className="companies">
        <DashboardTopBar>
          <h1 className="dashboard__header">Spółki</h1>
          <div className="companies__buttons">
            {tab === "my" ? (
              <a
                href="http://reliance.pro/inwestycje#konsultacja-inwestycje"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  onClick={() => null}
                  className="dashboard__top-button button--rounded button--hide-on-mobile button--transparent companies__meeting"
                  label="Umów spotkanie"
                  svg={SVG_ENUM.CALENDAR}
                />
              </a>
            ) : null}

            {tab === "my" || (isAdmin && tab === "reliance") ? (
              isCompanyInfoOpenModal && !isDesktop() ? (
                <Button
                  className="dashboard__top-button dashboard__top-button--no-menu button--rounded dashboard__fixed-button"
                  onClick={() =>
                    setAddClient(modalManage.company as CompanyType)
                  }
                  label="Dodaj transakcje  +"
                />
              ) : (
                <Button
                  className="dashboard__top-button button--rounded dashboard__fixed-button"
                  onClick={() => setAddCompany()}
                  label="Dodaj spółkę  +"
                />
              )
            ) : null}
          </div>
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <DashboardTopPortal>
              <div className="dashboard__tabs">
                {authorizedUser?.role === ROLES_ENUM.USER ? (
                  <span
                    onClick={() => handleChangeTab("my")}
                    className={`dashboard__tab ${
                      tab === "my" ? "dashboard__tab--active" : ""
                    } `}
                  >
                    Moje spółki
                  </span>
                ) : null}
                <span
                  onClick={() => handleChangeTab("reliance")}
                  className={`dashboard__tab ${
                    tab === "reliance" ? "dashboard__tab--active" : ""
                  } `}
                >
                  {authorizedUser?.role === ROLES_ENUM.ADMIN
                    ? "Spółki Reliance"
                    : "Inne spółki Reliance"}
                </span>
                {authorizedUser?.role === ROLES_ENUM.ADMIN ? (
                  <span
                    onClick={() => handleChangeTab("other")}
                    className={`dashboard__tab ${
                      tab === "other" ? "dashboard__tab--active" : ""
                    } `}
                  >
                    Pozostałe spółki
                  </span>
                ) : null}

                {authorizedUser?.role === ROLES_ENUM.USER &&
                isAnyCompanyInArchive ? (
                  <span
                    onClick={() => handleChangeTab("archive")}
                    className={`dashboard__tab ${
                      tab === "archive" ? "dashboard__tab--active" : ""
                    } `}
                  >
                    Archiwum
                  </span>
                ) : null}
              </div>
            </DashboardTopPortal>
            <CompaniesTable
              page={page}
              setPage={setPage}
              tab={tab}
              onRowClick={(company: CompanyType, e: any) =>
                setDetailsUser(company, e)
              }
              setEditCompany={setEditCompany}
              handleStatusChange={handleStatusChange}
              setShowSellShares={setShowSellShares}
              setShowDelete={setShowDelete}
              setModalManage={setModalManage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaniesPage;
