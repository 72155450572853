import React, { useState, DragEvent, useEffect } from "react";
import "./DropFileInput.scss";
import { FieldAttributes, useField, useFormikContext } from "formik";
import SVG from "shared/SVG";
import { SVG_ENUM } from "enums";

interface DropFileInputProps extends FieldAttributes<any> {
  label?: string | JSX.Element;
  name: string;
  className?: string;
  accept?: string;
  optional?: boolean;
}

const DropFileInput = ({
  name,
  className = "",
  label,
  optional,
  accept,
  ...props
}: DropFileInputProps) => {
  const [file, setFile] = useState<File | null>(null);
  const formik = useFormikContext();
  const [, meta] = useField({ ...props, name });

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  useEffect(() => {
    formik.setFieldValue(name, file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div className="drop-file-input__wrapper input__wrapper">
      <label className=" drop-file-input__container">
        <div className="input__label">
          {label} {optional && <p className="input__optional"> (opcjonalne)</p>}
        </div>
        <div
          className="drop-file-input__dropzone"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            onChange={handleFileSelect}
            className="drop-file-input__input"
            accept={accept}
          />
          <div className="drop-file-input__content">
            <SVG type={SVG_ENUM.CLOUD_UPLOAD} />
            <p>
              Upuść plik lub <strong>pobierz z dysku</strong>
            </p>
          </div>
        </div>
        {meta.touched && meta.error && (
          <div className="input__error">
            <span>{meta.error}</span>
          </div>
        )}
      </label>
      {file && (
        <p className="drop-file-input__file">
          {file.name}
          <span onClick={() => handleRemoveFile()}>
            <SVG type={SVG_ENUM.CLOSE_CIRCLE_GRAY} />
          </span>
        </p>
      )}
    </div>
  );
};

export default DropFileInput;
