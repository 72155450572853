import { SVG_ENUM } from "enums";
import React from "react";
import { SVG } from "shared";
import { profileTabsEnum } from "../ProfilePage";
import { isDesktop } from "utils";
import { useLogout } from "hooks/auth";

interface ProfileMenuProps {
  tab: profileTabsEnum | null;
  setTab: (tab: profileTabsEnum) => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ tab, setTab }) => {
  const { mutate: logout } = useLogout({});

  return (
    <ul className="profile__menu">
      <li
        className={`profile__menu-option ${
          tab === profileTabsEnum.personal ? "profile__menu-option--active" : ""
        }`}
        onClick={() => setTab(profileTabsEnum.personal)}
      >
        <span className="profile__menu-icon">
          <SVG type={SVG_ENUM.USER} />
        </span>
        Dane osobowe
        <SVG type={SVG_ENUM.CHEVRON_DOWN} className="profile__menu-chevron" />
      </li>
      <li
        className={`profile__menu-option ${
          tab === profileTabsEnum.location ? "profile__menu-option--active" : ""
        }`}
        onClick={() => setTab(profileTabsEnum.location)}
      >
        <span className="profile__menu-icon">
          <SVG type={SVG_ENUM.PIN} />
        </span>
        Dane adresowe
        <SVG type={SVG_ENUM.CHEVRON_DOWN} className="profile__menu-chevron" />
      </li>
      <li
        className={`profile__menu-option ${
          tab === profileTabsEnum.communication
            ? "profile__menu-option--active"
            : ""
        }`}
        onClick={() => setTab(profileTabsEnum.communication)}
      >
        <span className="profile__menu-icon">
          <SVG type={SVG_ENUM.COMMUNICATION} />
        </span>
        Komunikacja
        <SVG type={SVG_ENUM.CHEVRON_DOWN} className="profile__menu-chevron" />
      </li>
      <li
        className={`profile__menu-option ${
          tab === profileTabsEnum.payments ? "profile__menu-option--active" : ""
        }`}
        onClick={() => setTab(profileTabsEnum.payments)}
      >
        <span className="profile__menu-icon">
          <SVG type={SVG_ENUM.CREDIT_CARD} />
        </span>
        Płatności
        <SVG type={SVG_ENUM.CHEVRON_DOWN} className="profile__menu-chevron" />
      </li>
      <li
        className={`profile__menu-option ${
          tab === profileTabsEnum.contact ? "profile__menu-option--active" : ""
        }`}
        onClick={() => setTab(profileTabsEnum.contact)}
      >
        <span className="profile__menu-icon">
          <SVG type={SVG_ENUM.MESSAGE_DOT_CIRCLE} />
        </span>
        Kontakt
        <SVG type={SVG_ENUM.CHEVRON_DOWN} className="profile__menu-chevron" />
      </li>
      {isDesktop() ? null : (
        <li className="profile__menu-option" onClick={() => logout()}>
          <span className="profile__menu-icon">
            <SVG type={SVG_ENUM.LOGOUT} />
          </span>
          Wyloguj się
        </li>
      )}
    </ul>
  );
};

export default ProfileMenu;
