import { useState, FC } from "react";
import { useField, FieldAttributes, useFormikContext } from "formik";
import "./Input.scss";
import SVG from "shared/SVG";
import { SVG_ENUM } from "enums";

interface InputProps extends FieldAttributes<any> {
  type: string;
  label?: string | React.ReactNode;
  rightLabel?: React.ReactNode;
  onInputChange?: () => void;
  precision?: number;
  optional?: boolean;
}

const Input: FC<InputProps> = ({
  type,
  label,
  rightLabel,
  onInputChange,
  precision,
  optional,
  ...props
}) => {
  const [field, meta] = useField({ ...props, name: props.name });
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormikContext();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleType = () => {
    if (showPassword) {
      return "text";
    }

    if (type === "number") {
      return "text";
    }
    return type;
  };

  const handleOnChange = (value: any) => {
    if (type === "number") {
      const inputValue = value.target.value;

      const regex = precision
        ? new RegExp(`^\\d*(\\.\\d{0,${precision}})?$`)
        : /^\d*(\.\d*)?$/;

      if (!regex.test(inputValue) && inputValue !== "") {
        return;
      }
    }

    field.onChange(value);

    if (onInputChange) {
      onInputChange();
    }
  };

  const getPropsData = () => {
    const keys = Object.keys(props);
    const keysWithData = keys.filter((key) => key.includes("data-"));
    const data = keysWithData.reduce((acc, key) => {
      return { ...acc, [key]: props[key] };
    }, {});
    return data;
  };

  return (
    <div className="input__wrapper" {...getPropsData()}>
      {rightLabel ? (
        <div className="input__label-wrapper">
          <label className="input__label" htmlFor={props.id || props.name}>
            {label}{" "}
            {optional && <span className="input__optional">opcjonalne</span>}
          </label>
          {rightLabel}
        </div>
      ) : label ? (
        <label className="input__label" htmlFor={props.id || props.name}>
          {label}
          {optional && <p className="input__optional"> (opcjonalne)</p>}
        </label>
      ) : null}

      <input
        type={handleType()}
        {...field}
        {...props}
        value={field.value || ""}
        onChange={(value) => handleOnChange(value)}
        className="input"
        inputMode={type === "number" ? "decimal" : "text"}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            formik.handleSubmit();
          }
        }}
      />
      {type === "password" && (
        <span className="input__eye" onClick={handleTogglePassword}>
          <SVG type={showPassword ? SVG_ENUM.EYE : SVG_ENUM.CROSSED_EYE} />
        </span>
      )}
      {meta.touched && meta.error && (
        <div className="input__error">{meta.error}</div>
      )}
    </div>
  );
};

export default Input;
