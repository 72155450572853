import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";
import { CompanyUpdateClientType } from "types/CompanyTypes";

export const useUpdateCompanyClient = (
  options?: UseMutationOptions<any, Error, CompanyUpdateClientType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["updateCompany/client"],
    mutationFn: async (data) => {
      const formData = new FormData();
      formData.append("shareId", data.sharesId);
      formData.append("name", data.name);
      formData.append("industryUuid", data.industryUuid);
      formData.append("sharesCount", `${data.sharesCount}`);
      formData.append("completedAt", `${data.completedAt}`);
      if (data.sharesValue) {
        formData.append("sharesValue", `${data.sharesValue || null}`);
      }
      formData.append("currencyUuid", data.currencyUuid);
      formData.append("type", data.type);
      formData.append(
        "isHistoryAccepted",
        `${!!data.isHistoryAccepted ? 1 : 0}`
      );
      if (data.pricePerShare) {
        formData.append("pricePerShare", `${data.pricePerShare}`);
      }
      if (data.description) {
        formData.append("description", `${data.description}`);
      }
      if (data.invoice) {
        formData.append("invoice", data.invoice);
      }
      if (data.nip) {
        formData.append("nip", data.nip);
      }

      return axiosInstance
        .put("/companies/client", formData)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa dodawanie spółki...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "store-company",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Spółka dodana pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "store-company",
      });
      queryClient.invalidateQueries({ queryKey: ["companies/my"] });
      queryClient.invalidateQueries({ queryKey: ["shares/company"] });
      queryClient.invalidateQueries({ queryKey: ["transactions"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas dodawania spółki.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "store-company",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateCompanyClient;
