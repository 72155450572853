/* eslint-disable react-hooks/exhaustive-deps */
import Table from "rc-table";
import { mobileColumns, desktopColumns, summaryColumns } from "./utils";
import { isDesktop } from "utils";
// import { data } from "utils/userBase";
import { CompanyType } from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
import { useGetSharesByCompany } from "hooks/shares";
import "./ClientsTable.scss";

interface ClientsTableType {
  company: CompanyType | null;
}

const ClientsTable = ({ company }: ClientsTableType) => {
  const { data } = useGetSharesByCompany(company?.id ? company.id : undefined);
  const summaryValue = data
    ? data.reduce((acc, curr) => acc + curr.sharesValue, 0)
    : 0;
  const summaryCount = data
    ? data.reduce((acc, curr) => acc + curr.sharesCount, 0)
    : 0;

  const summary = {
    sharesCount: summaryCount,
    sharesValue: summaryValue,
    currencyUuid: company?.currencyUuid,
  };

  return (
    <div className="companies-table clients-table">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      <Table
        rowKey="id"
        className="companies-table"
        data={data}
        columns={
          isDesktop() ? desktopColumns({ company }) : mobileColumns({ company })
        }
      />

      {isDesktop() && !!summaryValue && !!company && (
        <Table
          className="clients-table__summary"
          rowKey="id"
          data={[summary]}
          columns={summaryColumns(company)}
        />
      )}
    </div>
  );
};

export default ClientsTable;
