import { SVG } from "shared";
import { SVG_ENUM } from "enums";
import { UserType } from "types/UsersTypes";
import { CompanyMyType } from "types/CompanyTypes";
import moment from "moment";
import { getCurrencyFromUuid, getIndustryFromUuid } from "utils";

export const mobileColumns = () => [
  {
    width: "calc(50% - 10px)",
    render: (values: any) => (
      <div className="users-table-mobile__name">{`${values.name}`}</div>
    ),
  },
  {
    width: 88,
    render: (item: UserType) => <SVG type={SVG_ENUM.DOTS} />,
  },
];

export const desktopColumns = ({ isOther = false }) => {
  let result = [
    {
      key: "index",
      width: 40,
      render: (value: any, record: CompanyMyType, index: number) => (
        <div
        data-tooltip-id="company-tooltip"
        data-tooltip-content={record.description}
        className="companies-table__info"
        >
          <SVG type={SVG_ENUM.INFO} />
        </div>
      ),
    },
    {
      title: "Nazwa spółki",
      dataIndex: "name",
      key: "name",
      width: 120,
    },
    {
      title: "Branża",
      width: 100,
      render: (value: any, record: CompanyMyType, index: number) => {
        return `${getIndustryFromUuid(record.industryUuid)}`;
      },
    },
    {
      title: "Wartość udziałów",
      width: 130,
      render: (value: any, record: CompanyMyType, index: number) =>
        record.sharesValue
          ? `${record.sharesValue} ${getCurrencyFromUuid(record.currencyUuid)}`
          : "-",
    },
    {
      title: isOther ? null : "Wycena spółki",
      width: 120,
      render: (value: any, record: CompanyMyType, index: number) =>
        isOther
          ? null
          : `${record.sharePrice} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Data zakupu",
      width: 170,
      render: (value: any, record: CompanyMyType, index: number) =>
        record.createdAt
          ? `${moment(
              record.createdAt,
              "YYYY - MM - DDTHH: mm: ss.SSSZ"
            ).format("DD/MM/YYYY")}r.`
          : "",
    },
    {
      title: "Data sprzedaży",
      width: 170,
      render: (value: any, record: CompanyMyType, index: number) =>
        record.archivedAt
          ? `${moment(record.archivedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
              "DD/MM/YYYY"
            )}r.`
          : "",
    },

    {
      title: "",
      dataIndex: "",
      key: "status",
      width: 40,
      render: () => (
        <div className="companies-table__announcement">
          <SVG type={SVG_ENUM.ANNOUCEMENT} />
          <span>0</span>
        </div>
      ),
    },
    {
      width: 20,
      render: (item: CompanyMyType) => <SVG type={SVG_ENUM.DOTS} />,
    },
  ];

  return result;
};
