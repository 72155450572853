import { SVG_ENUM } from "enums";
import SVG from "shared/SVG";
import "./Warning.scss";

interface WarningProps {
  svg?: SVG_ENUM;
  text: string | JSX.Element;
  className?: string;
}

const Warning: React.FC<WarningProps> = ({
  svg,
  text,
  className = "",
}: WarningProps) => {
  return (
    <div className={`warning ${className}`}>
      <div className="warning__svg">
        <SVG type={svg || SVG_ENUM.ANNOTATION_ALERT} />
      </div>
      <div className="warning__text">{text}</div>
    </div>
  );
};

export default Warning;
