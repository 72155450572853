// FullscreenModal.tsx
import React, { FC, ReactNode, useEffect } from "react";
import { Transition } from "react-transition-group";
import "./FullscreenModal.scss";
import Button from "shared/Button/Button";
import { SVG_ENUM } from "enums";
import { createPortal } from "react-dom";

interface FullscreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

const FullscreenModal: FC<FullscreenModalProps> = ({
  isOpen,
  onClose,
  children,
  className = "",
}) => {
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
  };

  const transitionStyles: { [key: string]: React.CSSProperties } = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const html = document.querySelector("html");
  useEffect(() => {
    if (isOpen) {
      html?.classList.add("modal-open");
    } else {
      html?.classList.remove("modal-open");
    }
  }, [isOpen, html]);

  return createPortal(
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <div
          className="fullscreen-modal-overlay"
          // onClick={onClose}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <Button
            className="button--stroke button--stroke button--rounded fullscreen-modal__close button--max-h-40"
            onClick={() => onClose()}
            label="Zamknij"
            svg={SVG_ENUM.CLOSE}
          />
          <div
            className={`fullscreen-modal ${className}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="fullscreen-modal__content">{children}</div>
          </div>
        </div>
      )}
    </Transition>,
    document.querySelector(".App") ?? document.body
  );
};

export default FullscreenModal;
