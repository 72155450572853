import { Button, Modal } from "shared";
import { useDeleteCompanyClient } from "hooks/companies";
import { CompanyMyType } from "types/CompanyTypes";
// import { UserType } from "types/UsersTypes";

interface BlockUserType {
  company: CompanyMyType;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const DeleteCompanyModal = ({ isOpen, onClose, company, onSuccess }: BlockUserType) => {

  const { mutate: deleteCompanyClient } = useDeleteCompanyClient();

  const handleDeleteCompany = async () => {
    if (company) {
      await deleteCompanyClient(
        { sharesId: company?.sharesId },
        {
          onSuccess: () => {
            onSuccess && onSuccess();
            onClose();
          },
        }
      );
      onClose();
    }
  };
  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">usuwanie spółki</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć spółkę
            <span>{`${company?.name} `}</span>?
          </div>
          <div className="modal-buttons">
            <Button
              className="button--stroke button--rounded"
              onClick={() => onClose()}
              label="Anuluj"
            />
            <Button
              className="button--black button--rounded"
              onClick={() => handleDeleteCompany()}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteCompanyModal;
