import { Button, SideModal } from "shared";
import { ClientsTable } from "./components";
import { CompanyType } from "types/CompanyTypes";
import "./CompanyInfoModal.scss";

interface CompanyInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyType | null;
  setAddClient: (company: CompanyType) => void;
}

const CompanyInfoModal: React.FC<CompanyInfoModalProps> = ({
  isOpen,
  onClose,
  company,
  setAddClient,
}) => {
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Lista transakcji"
      subtitle={company?.name}
      className="company-info-modal wide"
    >
      <>
        <Button
          className="button--rounded company-info-modal__button"
          onClick={() => company && setAddClient(company)}
          label="Dodaj transakcje  +"
        />
        {isOpen && <ClientsTable company={company} />}
      </>
    </SideModal>
  );
};

export default CompanyInfoModal;
