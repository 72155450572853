import { SVG_ENUM } from "enums";
import "./LargeSwitch.scss";
import SVG from "shared/SVG";

type P = {
  onChange: (value: any) => void;
  state: string;
  options: { value: string; label: string }[];
  className?: string;
};

const LargeSwitch = ({ onChange, state, options, className = "" }: P) => {
  const isActive = (option: string) => state === option;
  return (
    <>
      <div className={`large-switch ${className}`}>
        {options.map((option) => (
          <span
            key={option.value}
            className={`large-switch__option ${
              isActive(option.value) ? "large-switch__option--active" : ""
            }`}
            onClick={() => onChange(option.value)}
          >
            {option.label}
            <SVG
              type={SVG_ENUM.CIRCLE_CHECK}
              className={`large-switch__icon ${
                isActive(option.value) ? "large-switch__icon--active" : ""
              }`}
            />
          </span>
        ))}
      </div>
    </>
  );
};

export default LargeSwitch;
