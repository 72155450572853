import { UserStatus, ContextMenu, SVG } from "shared";
import { ROLES_ENUM, SVG_ENUM } from "enums";
import { UserType } from "types/UsersTypes";
import { getPrefixFromUuid } from "utils";
import { PhonePrefixType } from "types/CountriesTypes";

const items = (
  isAdminTab: boolean,
  blockUser: (user: UserType) => void,
  editUser: (user: UserType) => void,
  resendActivateMail: (email: string) => void,
  currentUser: UserType | undefined,
  changePassword: () => void,
  editAdmin: (user: UserType) => void
) => {
  return (user: UserType) => {
    const actions = [
      {
        name: "Edytuj",
        action: () =>
          user.role === ROLES_ENUM.ADMIN ? editAdmin(user) : editUser(user),
        svg: SVG_ENUM.EDIT,
      },
    ];
    if (isAdminTab) {
      if (user.status === "inactive") {
        actions.push({
          name: "Wyślij link aktywacyjny",
          action: () => resendActivateMail(user.email),
          svg: SVG_ENUM.LINK,
        });
      }
    }
    if (user.id !== currentUser?.id) {
      if (user.status !== "inactive") {
        actions.push({
          name: user.status === "suspended" ? "Przywróć" : "Zawieś",
          action: () => blockUser(user),
          svg:
            user.status === "suspended"
              ? SVG_ENUM.ARROW_UNDO
              : SVG_ENUM.STOP_SIGN,
        });
      }
    } else {
      actions.push({
        name: "Zmień hasło",
        action: () => changePassword(),
        svg: SVG_ENUM.LOCK,
      });
    }
    return actions;
  };
};

export const mobileColumns = (
  isAdminTab: boolean,
  currentPage: number,
  perPage: number,
  blockUser: (user: UserType) => void,
  deleteUser: (user: UserType) => void,
  editUser: (user: UserType) => void,
  resendActivateMail: (email: string) => void,
  user: UserType | undefined,
  changePassword: () => void,
  phonePrefixes: PhonePrefixType[] | [],
  editAdmin: (user: UserType) => void
) =>
  isAdminTab
    ? [
        {
          width: "calc(50% - 10px)",
          render: (values: any) => (
            <div className="users-table-mobile__name">{`${values.name}`}</div>
          ),
        },
        {
          dataIndex: "status",
          key: "status",
          width: 100,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          width: 88,
          render: (item: UserType) => (
            <ContextMenu
              data={item}
              items={items(
                isAdminTab,
                blockUser,
                editUser,
                resendActivateMail,
                user,
                changePassword,
                editAdmin
              )(item)}
            >
              <SVG type={SVG_ENUM.DOTS} />
            </ContextMenu>
          ),
        },
      ]
    : [
        {
          width: "calc(50% - 10px)",
          render: (values: any) => (
            <div className="users-table-mobile__name">{`${values.name}`}</div>
          ),
        },
        {
          dataIndex: "status",
          key: "status",
          width: 100,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          width: 88,
          render: (item: UserType) => (
            <ContextMenu
              data={item}
              items={items(
                isAdminTab,
                blockUser,
                editUser,
                resendActivateMail,
                user,
                changePassword,
                editAdmin
              )(item)}
            >
              <SVG type={SVG_ENUM.DOTS} />
            </ContextMenu>
          ),
        },
      ];

export const desktopColumns = (
  isAdminTab: boolean,
  currentPage: number,
  perPage: number,
  blockUser: (user: UserType) => void,
  deleteUser: (user: UserType) => void,
  editUser: (user: UserType) => void,
  resendActivateMail: (email: string) => void,
  user: UserType | undefined,
  changePassword: () => void,
  phonePrefixes: PhonePrefixType[] | [],
  editAdmin: (user: UserType) => void
) =>
  isAdminTab
    ? [
        {
          title: "Lp.",
          key: "id",
          dataIndex: "id",
          width: 68,
        },
        {
          title: "Imie i nazwisko",
          dataIndex: "name",
          key: "name",
          width: 300,
        },

        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 350,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 201,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          width: 88,
          render: (item: UserType) => (
            <ContextMenu
              data={item}
              items={items(
                isAdminTab,
                blockUser,
                editUser,
                resendActivateMail,
                user,
                changePassword,
                editAdmin
              )(item)}
            >
              <SVG type={SVG_ENUM.DOTS} />
            </ContextMenu>
          ),
        },
      ]
    : [
        {
          title: "Lp.",
          key: "id",
          dataIndex: "id",
          width: 68,
        },
        {
          title: "Klient",
          dataIndex: "name",
          key: "name",
          width: 220,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 350,
        },
        {
          title: "Telefon",
          width: 196,
          render: (value: UserType) =>
            `${
              value?.phonePrefixUuid
                ? getPrefixFromUuid(value?.phonePrefixUuid, phonePrefixes)
                : ""
            } ${value?.phoneNumber ? value?.phoneNumber : ""}`,
        },
        {
          title: "Pakiet",
          width: 196,
          dataIndex: "package",
          key: "package",
        },
        {
          title: "Wykupione spółki",
          width: 196,
          dataIndex: "totalSharesCount",
          key: "totalSharesCount",
        },

        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 201,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          width: 88,
          render: (item: UserType) => (
            <ContextMenu
              data={item}
              items={items(
                isAdminTab,
                blockUser,
                editUser,
                resendActivateMail,
                user,
                changePassword,
                editAdmin
              )(item)}
            >
              <SVG type={SVG_ENUM.DOTS} />
            </ContextMenu>
          ),
        },
      ];
