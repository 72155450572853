/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { Pagination } from "shared";
import { isDesktop } from "utils";
import { useGetUsers } from "hooks/users";
// import { data } from "utils/userBase";
import "./users-table.scss";
import { UserIndexType, UserType } from "types/UsersTypes";
import { useSearchParams } from "react-router-dom";
import { ROLES_ENUM } from "enums";
import { useGetPhonePrefixes } from "hooks/phone";

interface UsersTableType {
  blockUser: (user: UserType) => void;
  deleteUser: (user: UserType) => void;
  editUser: (user: UserType) => void;
  editAdmin: (user: UserType) => void;
  resendActivateMail: (email: string) => void;
  isAdminTab: boolean;
  page: number;
  setPage: (page: number) => void;
  tab: ROLES_ENUM;
  changePassword: () => void;
  user: UserType | undefined;
  onRowClick?: (
    record: UserIndexType,
    e: React.MouseEvent<any, MouseEvent>
  ) => void;
}

const UsersTable = ({
  resendActivateMail,
  blockUser,
  deleteUser,
  editUser,
  isAdminTab,
  setPage,
  page,
  tab,
  editAdmin,
  changePassword,
  user,
  onRowClick,
}: UsersTableType) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, refetch } = useGetUsers(tab, page);
  const { data: phonePrefixes } = useGetPhonePrefixes();
  useEffect(() => {
    refetch();
  }, [page, tab]);

  const handleExpand = (expanded: any, record: any) => {
    if (!isDesktop()) {
      const keys = expanded ? [record.id] : [];
      setExpandedRowKeys(keys);
    }
  };

  useEffect(() => {
    if (data && data?.data?.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [data, page]);

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    newSearchParams.set("role", tab);
    setSearchParams(newSearchParams);
  };
  return (
    <div className="users-table">
      <Table
        rowKey="id"
        className="user-table"
        data={data?.data}
        onRow={(record: any) => ({
          onClick: (e) => onRowClick && onRowClick(record, e),
        })}
        columns={
          isDesktop()
            ? desktopColumns(
                isAdminTab,
                data?.meta?.currentPage || 0,
                data?.meta?.perPage || 0,
                blockUser,
                deleteUser,
                editUser,
                resendActivateMail,
                user,
                changePassword,
                phonePrefixes,
                editAdmin
              )
            : mobileColumns(
                isAdminTab,
                data?.meta?.currentPage || 0,
                data?.meta?.perPage || 0,
                blockUser,
                deleteUser,
                editUser,
                resendActivateMail,
                user,
                changePassword,
                phonePrefixes,
                editAdmin
              )
        }
        expandable={{
          showExpandColumn: false,
          expandRowByClick: isAdminTab,
          expandedRowKeys,
          childrenColumnName: "children",

          onExpand: handleExpand,
          expandedRowRender: (record) => (
            <div className="user-table-row__expand">
              <div className="user-table-row__expand-top">
                <div className="user-table-row__grid">
                  <div className="user-table-row__grid-item user-table-row__grid-item--label">
                    Email:
                  </div>
                  <div className="user-table-row__grid-item">
                    {record.email}
                  </div>
                </div>
              </div>
            </div>
          ),
        }}
      />
      {data?.meta && data?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={data?.meta?.lastPage}
          currentPage={data?.meta?.currentPage || page}
          onPageChange={(e: any) => handleChangePage(e)}
        />
      )}
    </div>
  );
};

export default UsersTable;
