import { SVG_ENUM } from "enums";
import "./DashboardTopBar.scss";
import { SVG } from "shared";
import { isDesktop } from "utils";
import { useGetMe, useLogout } from "hooks/auth";

interface DashboardTopBarType {
  children: React.ReactNode;
}

const DashboardTopBar = ({ children }: DashboardTopBarType) => {
  const { mutate: logout } = useLogout({});
  const { data: authorizedUser } = useGetMe();
  return (
    <>
      <div className="dtb__wrapper">
        {authorizedUser?.role === "admin" && (
          <span className="dtb__logout" onClick={() => logout()}>
            Wyloguj <SVG type={SVG_ENUM.LOGOUT_2} />
          </span>
        )}
        <SVG
          type={
            isDesktop()
              ? SVG_ENUM.DASHBOARD_BG_DESKTOP
              : SVG_ENUM.DASHBOARD_BG_MOBILE
          }
          className="dtb__bg"
        />
        <div className="dtb">{children}</div>
      </div>
    </>
  );
};

export default DashboardTopBar;
