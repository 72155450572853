import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { TransactionTypeAdmin } from "types/TransactionsTypes";

export default function useGetTransactionsByUserUuid(uuid?: number) {
  return useQuery({
    queryKey: ["transactions", "transactions/user", uuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/transactions/user/${uuid}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: TransactionTypeAdmin[]) => data,
    retry: false,
    enabled: !!uuid,
    staleTime: 1000 * 60 * 5,
    initialDataUpdatedAt: Date.now(),
  });
}
