import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreTransaction } from "hooks/transactions";
import moment from "moment";
import {
  Button,
  CalendarInput,
  DropFileInput,
  FullscreenModal,
  Input,
  Warning,
} from "shared";
import { CompanyMyType } from "types/CompanyTypes";
import { TransactionStoreType } from "types/TransactionsTypes";
import { getCurrencyFromUuid, getCurrencySymbol } from "utils";
import * as Yup from "yup";

interface SellSharesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company: CompanyMyType | null;
}

const SellSharesModal: React.FC<SellSharesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeTransaction } = useStoreTransaction();
  const isStock = company?.type === "stock";
  const initialValues = {
    sharesCount: null as number | null,
    pricePerShare: company?.pricePerShare || null,
    sharesValue: null as number | null,
    invoice: null,
    completedAt: moment().format("YYYY-MM-DD"),
  };

  const onSubmit = async (values: TransactionStoreType) => {
    const summary =
      values.sharesValue && values.sharesCount
        ? Number.parseFloat(
            (values.sharesValue / values.sharesCount).toFixed(2)
          )
        : 0;

    company &&
      (await storeTransaction(
        {
          ...values,
          sharesId: company?.sharesId,
          type: "sell",
          pricePerShare: summary || undefined,
        },
        {
          onSuccess: () => {
            onSuccess && onSuccess();
            onClose();
          },
        }
      ));

    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">Zaznacz sprzedaż</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit, setFieldValue }) => {
          const summary =
            values.sharesValue && values.sharesCount
              ? (values.sharesValue / values.sharesCount).toFixed(2)
              : 0;

          const isMoreThanAvailable =
            company?.sharesCount &&
            values?.sharesCount &&
            values?.sharesCount > company?.sharesCount;
          return (
            <Form className="m-width-100 fullscreen-modal__select-margin">
              <Field
                type="number"
                id="sharesCount"
                name="sharesCount"
                precision={3}
                label={`Ilość sprzedanych ${isStock ? "akcji" : "udziałów"}`}
                as={Input}
              />
              <Warning
                className={isMoreThanAvailable ? "" : "warning--gray"}
                text={`Nie możesz sprzedać więcej udziałów niż posiadasz. Obecnie posiadasz ${company?.sharesCount}.`}
              />
              <div className="input--currency">
                <Field
                  type="number"
                  id="sharesValue"
                  name="sharesValue"
                  optional
                  precision={2}
                  label={`Wartość sprzedanych ${
                    isStock ? "akcji" : "udziałów"
                  }`}
                  as={Input}
                />
                <span>
                  {company?.currencyUuid
                    ? getCurrencySymbol(
                        getCurrencyFromUuid(company?.currencyUuid)
                      )
                    : null}
                </span>
              </div>
              <div className={`add-share__summary`}>
                <label className="input__label">
                  Wartość {isStock ? "jednej akcji" : "jednego udziału"}
                </label>
                <input
                  type="text"
                  className="input"
                  readOnly
                  value={summary ? summary : 0}
                />
                <span>
                  {company?.currencyUuid &&
                    getCurrencySymbol(
                      getCurrencyFromUuid(company?.currencyUuid)
                    )}
                </span>
              </div>
              <Field
                type="date"
                id="completedAt"
                name="completedAt"
                label={"Data transakcji"}
                component={CalendarInput}
                errors={errors}
              />

              <Field
                label="Umowa (opcjonalne)"
                name="invoice"
                id="invoice"
                as={DropFileInput}
                accept=".pdf"
              />

              <div className="fullscreen-modal__buttons">
                <Button
                  label="Anuluj"
                  onClick={onClose}
                  className="button--stroke button--rounded"
                />
                <Button
                  label={"Potwierdź"}
                  type="submit"
                  onClick={() => null}
                  disabled={!!isMoreThanAvailable}
                  className=" button--rounded button--black"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </FullscreenModal>
  );
};

export default SellSharesModal;
