import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";
import { CompanyUpdateType } from "types/CompanyTypes";

export const useUpdateCompany = (
  options?: UseMutationOptions<any, Error, CompanyUpdateType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["updateCompany"],
    mutationFn: async (data) => {
      return axiosInstance
        .put("/companies/" + data.id, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa aktualizacja spółki...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "update-company",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Spółka zaktualizowana pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "update-company",
      });
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas aktualizacji spółki.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "update-company",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateCompany;
