import { useGetSharesSummary } from "hooks/shares";
import "./CompaniesSummary.scss";
import { useEffect, useState } from "react";

const CompaniesSummary = () => {
  const { data: summary } = useGetSharesSummary();
  const [summaryData, setSummaryData] = useState({
    totalValue: 0,
    totalCount: 0,
    currency: "PLN",
  });

  useEffect(() => {
    if (summary) {
      if (summary.PLN.totalCount > 0) {
        setSummaryData({
          totalValue: summary.PLN.totalValue,
          totalCount: summary.PLN.totalCount,
          currency: "PLN",
        });
        return;
      }

      if (summary.USD.totalCount > 0) {
        setSummaryData({
          totalValue: summary.USD.totalValue,
          totalCount: summary.USD.totalCount,
          currency: "USD",
        });
        return;
      }

      if (summary.EUR.totalCount > 0) {
        setSummaryData({
          totalValue: summary.EUR.totalValue,
          totalCount: summary.EUR.totalCount,
          currency: "EUR",
        });
        return;
      }
    }
  }, [summary]);

  return (
    <div className="summary">
      <div className="summary__container">
        <h3>Wartość wykupionych udziałów</h3>
        <p>
          {summaryData?.totalValue}{" "}
          <span className="summary__currency">{summaryData?.currency}</span>
        </p>
      </div>
      <div className="summary__container">
        <h3>Ilość wykupionych spółek</h3>
        <p>{summary?.combined?.totalCount || 0}</p>
      </div>
    </div>
  );
};

export default CompaniesSummary;
