import { SVG_ENUM } from "enums";
import { EditAddressDataModal } from "features/users";
import { useGetMe } from "hooks/auth";
import { useCommunicationPreference } from "hooks/users";
import React, { useState } from "react";
import { SVG, Switch } from "shared";
import { UserType } from "types/UsersTypes";

interface CommunicationProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const Communication: React.FC<CommunicationProps> = ({
  user,
  handleGoBack,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: authorizedUser } = useGetMe();
  const { mutate: updateCommunication } = useCommunicationPreference();
  const communicationPreference = authorizedUser?.communicationPreference;
  const isSms =
    communicationPreference === "sms" || communicationPreference === "both";
  const isEmail =
    communicationPreference === "email" || communicationPreference === "both";

  const handleChange = ({
    type,
    value,
  }: {
    type: UserType["communicationPreference"];
    value: boolean;
  }) => {
    let newValue: UserType["communicationPreference"] = null;

    if ((type === "sms" && value) || (type === "email" && isSms)) {
      newValue = "sms";
    }

    if ((type === "email" && value) || (type === "sms" && isEmail)) {
      if (newValue === "sms") {
        newValue = "both";
      } else {
        newValue = "email";
      }
    }

    if (authorizedUser && authorizedUser.id) {
      updateCommunication({
        communicationPreference: newValue,
      });
    }
  };

  return (
    <div className="profile__right-wrapper">
      <EditAddressDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user || null}
      />
      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.USER} />
        </span>
        Komunikacja
      </h2>

      <h3 className="profile__menu-subheader">
        Wybierz sposób komunikacji. Możemy przesyłać Ci powiadomienia
        na telefon — za pomocą SMS lub/i drogą mailową.
      </h3>

      <div className="profile__communication-option">
        SMS
        <Switch
          state={isSms}
          onClick={() =>
            handleChange({
              type: "sms",
              value: !isSms,
            })
          }
        />
      </div>
      <div className="profile__communication-option">
        Email
        <Switch
          state={isEmail}
          onClick={() =>
            handleChange({
              type: "email",
              value: !isEmail,
            })
          }
        />
      </div>
    </div>
  );
};

export default Communication;
