import { SVG_ENUM } from "enums";
import { useGetAddress } from "hooks/addresses";
import { useGetCountries, useGetPhonePrefixes } from "hooks/countries";
import React, { useEffect } from "react";
import { Button, UserStatus } from "shared";
import { UserType } from "types/UsersTypes";
import { getPrefixFromUuid } from "utils";

interface DataTableProps {
  user: UserType | null;
  setEditPersonalData: (user: UserType) => void;
}

const DataTable: React.FC<DataTableProps> = ({ user, setEditPersonalData }) => {
  const { data, refetch } = useGetAddress(user?.uuid);
  const { data: countries } = useGetCountries();
  const country = countries?.find(
    (country) => country.value === data?.countryUuid
  );

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user, refetch]);

  const isShowCompanyData = !data?.type || data?.type === "company";
  const { data: prefixNumbers } = useGetPhonePrefixes();
  return (
    <>
      <h2 className="side-modal__table-heading">Informacje systemowe</h2>
      <table className="side-modal__table">
        <tr>
          <td className="side-modal__table-label">Imię i nazwisko:</td>
          <td className="side-modal__table-cell">{user?.name || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Telefon:</td>
          <td className="side-modal__table-cell">
            {user?.phonePrefixUuid
              ? getPrefixFromUuid(user?.phonePrefixUuid, prefixNumbers)
              : ""}{" "}
            {user?.phoneNumber || "-"}
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Wykupione spółki:</td>
          <td className="side-modal__table-cell">
            {user?.totalSharesCount || 0}
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Status:</td>
          <td className="side-modal__table-cell">
            {user?.status ? <UserStatus status={user?.status} /> : "-"}
          </td>
        </tr>
      </table>
      <h2 className="side-modal__table-heading">Dane do Faktury</h2>
      <table className="side-modal__table">
        {isShowCompanyData ? (
          <tr>
            <td className="side-modal__table-label">Nazwa firmy:</td>
            <td className="side-modal__table-cell">
              {data?.companyName || "-"}
            </td>
          </tr>
        ) : null}
        {isShowCompanyData ? (
          <tr>
            <td className="side-modal__table-label">NIP:</td>
            <td className="side-modal__table-cell">{data?.nip || "-"}</td>
          </tr>
        ) : null}
        <tr>
          <td className="side-modal__table-label">Kod pocztowy:</td>
          <td className="side-modal__table-cell">{data?.postalCode || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Miasto:</td>
          <td className="side-modal__table-cell">{data?.city || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Kraj:</td>
          <td className="side-modal__table-cell">{country?.label || "-"}</td>
        </tr>
      </table>
      <Button
        label="Edytuj dane do faktury"
        className="button--rounded user-info-modal__invoice-button"
        svg={SVG_ENUM.EDIT}
        onClick={() => user && setEditPersonalData(user)}
      />
    </>
  );
};

export default DataTable;
