// TimePicker.tsx
import React, { useState, useEffect, useRef } from "react";
import { FieldProps } from "formik";
import "./TimePicker.scss";
import SVG from "shared/SVG";
import { SVG_ENUM } from "enums";

type TimePickerProps = FieldProps & {
  onTimeChange?: (time: string) => void;
  label?: string;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value: any) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data: any) => void;
  onFocus: any;
  onBlur?: any;
};

const TimePicker: React.FC<TimePickerProps> = ({
  field,
  form,
  onTimeChange,
  label,
  errors,
}) => {
  const [selectedHour, setSelectedHour] = useState<number>(8);
  const [selectedMinute, setSelectedMinute] = useState<number>(40);
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  const [hoverHour, setHoverHour] = useState<number | null>(null);
  const [hoverMinute, setHoverMinute] = useState<number | null>(null);

  const popupRef = useRef<HTMLDivElement>(null);
  const hoursRef = useRef<HTMLDivElement>(null);
  const minutesRef = useRef<HTMLDivElement>(null);

  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  useEffect(() => {
    if (isPickerOpen) {
      const hourElement = hoursRef.current?.querySelector(`.item.selected`);
      const minuteElement = minutesRef.current?.querySelector(`.item.selected`);

      hourElement?.scrollIntoView({ block: "center", behavior: "smooth" });
      minuteElement?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [isPickerOpen]);

  useEffect(() => {
    if (field.value) {
      const [hour, minute] = field.value.split(":").map(Number);
      setSelectedHour(hour);
      setSelectedMinute(minute);
    }
  }, [field.value]);

  const handleHourChange = (hour: number) => {
    setSelectedHour(hour);
    const newTime = `${hour.toString().padStart(2, "0")}:${selectedMinute
      .toString()
      .padStart(2, "0")}`;
    form.setFieldValue(field.name, newTime);
    onTimeChange?.(newTime);
  };

  const handleMinuteChange = (minute: number) => {
    setSelectedMinute(minute);
    const newTime = `${selectedHour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
    form.setFieldValue(field.name, newTime);
    onTimeChange?.(newTime);
  };

  const scrollHours = (direction: "up" | "down") => {
    if (hoursRef.current) {
      const scrollAmount = direction === "up" ? -40 : 40;
      hoursRef.current.scrollBy({ top: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollMinutes = (direction: "up" | "down") => {
    if (minutesRef.current) {
      const scrollAmount = direction === "up" ? -40 : 40;
      minutesRef.current.scrollBy({ top: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="time-picker-container">
      <label className="calendar-input__label" htmlFor={field.name}>
        {label}{" "}
      </label>
      <div className="calendar-input__icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
            stroke="#096EB5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <input
        type="text"
        className="time-input"
        value={`${selectedHour.toString().padStart(2, "0")}:${selectedMinute
          .toString()
          .padStart(2, "0")}`}
        readOnly
        onClick={() => setIsPickerOpen(!isPickerOpen)}
      />
      {isPickerOpen && (
        <div className="time-picker" ref={popupRef}>
          <div className="dropdown">
            <div className="hours__wrapper">
              <span onClick={() => scrollHours("up")}>
                {" "}
                <SVG type={SVG_ENUM.CHEVRON_UP} />{" "}
              </span>
              <div className="hours" ref={hoursRef}>
                {hours.map((hour) => (
                  <div
                    key={hour}
                    className={`item ${
                      hour === selectedHour ? "selected" : ""
                    } ${hour === hoverHour ? "hover" : ""}`}
                    onClick={() => handleHourChange(hour)}
                    onMouseEnter={() => setHoverHour(hour)}
                    onMouseLeave={() => setHoverHour(null)}
                  >
                    {hour.toString().padStart(2, "0")}
                  </div>
                ))}
              </div>
              <span onClick={() => scrollHours("down")}>
                {" "}
                <SVG type={SVG_ENUM.CHEVRON_DOWN} />{" "}
              </span>
            </div>

            <div className="minutes__wrapper">
              <span onClick={() => scrollMinutes("up")}>
                {" "}
                <SVG type={SVG_ENUM.CHEVRON_UP} />{" "}
              </span>
              <div className="minutes" ref={minutesRef}>
                {minutes.map((minute) => (
                  <div
                    key={minute}
                    className={`item ${
                      minute === selectedMinute ? "selected" : ""
                    } ${minute === hoverMinute ? "hover" : ""}`}
                    onClick={() => handleMinuteChange(minute)}
                    onMouseEnter={() => setHoverMinute(minute)}
                    onMouseLeave={() => setHoverMinute(null)}
                  >
                    {minute.toString().padStart(2, "0")}
                  </div>
                ))}
              </div>
              <span onClick={() => scrollMinutes("down")}>
                {" "}
                <SVG type={SVG_ENUM.CHEVRON_DOWN} />{" "}
              </span>
            </div>
          </div>
        </div>
      )}
      {errors && form.touched && (
        <div className="calendar-input__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
};

export default TimePicker;
