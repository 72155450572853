import Table from "rc-table";
import React, { useEffect } from "react";
import { UserType } from "types/UsersTypes";
import { desktopColumns } from "./utils";
import "./SharesTable.scss";
import { useGetTransactionsByUserUuid } from "hooks/transactions";

interface SharesTableProps {
  user: UserType | null;
}

const SharesTable: React.FC<SharesTableProps> = ({ user }) => {
  const { data, refetch } = useGetTransactionsByUserUuid(user?.id || undefined);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user, refetch]);

  const relianceData = data?.filter(
    (item) => item?.share?.company?.origin === "reliance"
  );

  const otherData = data?.filter(
    (item) => item?.share?.company?.origin !== "reliance"
  );

  return (
    <div className="companies-table">
      {!!relianceData?.length && (
        <>
          <h2>Spółki reliance</h2>

          <Table
            className="clients-table"
            rowKey="id"
            data={relianceData}
            columns={desktopColumns()}
          />
        </>
      )}
      {!!otherData?.length && (
        <>
          <h2>Spółki zewnętrzne</h2>
          <Table
            className="clients-table"
            rowKey="id"
            data={otherData}
            columns={desktopColumns()}
          />
        </>
      )}
      {!relianceData?.length && !otherData?.length && (
        <h3 className="side-modal__table-heading">Brak danych</h3>
      )}
    </div>
  );
};

export default SharesTable;
