import { useGetMyCompanies } from "hooks/companies";
import Table from "rc-table";
import { isDesktop } from "utils";
import { desktopColumns, mobileColumns } from "./utilsMyTab";
import CompaniesSummary from "../CompaniesSummary/CompaniesSummary";
import { CompanyMyType } from "types/CompanyTypes";
import { DashboardTopPortal } from "shared";
import { useGetUnviewedPostsCount } from "hooks/posts";

interface MyCompaniesTabType {
  tab: "my" | "other";
  onRowClick?: (record: any, e: React.MouseEvent<any, MouseEvent>) => void;
  setEditCompany: (company: any) => void;
  handleStatusChange: (company: any, status: any) => void;
  industries: any;
  setShowSellShares: (company: CompanyMyType) => void;
  setShowDelete: (company: CompanyMyType) => void;
}

const MyCompaniesTab = ({
  tab,
  onRowClick,
  setEditCompany,
  handleStatusChange,
  industries,
  setShowSellShares,
  setShowDelete,
}: MyCompaniesTabType) => {
  const { data: myRelianceData } = useGetMyCompanies({
    origin: "reliance",
    enabled: tab === "my",
  });
  const { data: myOtherData } = useGetMyCompanies({
    origin: "other",
    enabled: tab === "my",
  });

  const combinedIds = [...(myRelianceData || []), ...(myOtherData || [])].map(
    (item) => item.id
  );

  const { data: unviewedPostCount } = useGetUnviewedPostsCount({
    companyIds: combinedIds,
  });

  const isEmptyState = !myRelianceData?.length && !myOtherData?.length;
  return (
    <>
      {isDesktop() ? (
        <DashboardTopPortal>
          <CompaniesSummary />
        </DashboardTopPortal>
      ) : (
        <CompaniesSummary />
      )}

      {isEmptyState ? (
        <div className="companies-table__empty">
          W tym miejscu pojawi się lista Twoich spółek zakupionych
          w&nbsp;Reliance.
          <br className="desktop" /> Aby dodać spółkę, kliknij przycisk “Dodaj
          spółkę” w&nbsp;prawym górnym rogu.
        </div>
      ) : (
        <>
          <h2 className="companies-table__title">
            Spółki wykupione w Reliance
          </h2>
          <Table
            rowKey="id"
            className="companies-table"
            data={myRelianceData}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={
              isDesktop()
                ? desktopColumns({
                    setEditCompany,
                    unviewedPostCount,
                    setShowSellShares,
                  })
                : mobileColumns({
                    setEditCompany,
                    unviewedPostCount,
                    setShowSellShares,
                  })
            }
          />
          {myOtherData?.length ? (
            <>
              <h2 className="companies-table__title companies-table__title--other">
                Inne spółki
              </h2>
              <Table
                rowKey="id"
                data={myOtherData}
                className="companies-table"
                onRow={(record: any) => ({
                  onClick: (e) => onRowClick && onRowClick(record, e),
                })}
                columns={
                  isDesktop()
                    ? desktopColumns({
                        setEditCompany,
                        isOther: true,
                        setShowSellShares,
                        setShowDelete,
                        unviewedPostCount,
                      })
                    : mobileColumns({
                        setEditCompany,
                        setShowSellShares,
                        setShowDelete,
                        unviewedPostCount,
                      })
                }
              />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MyCompaniesTab;
